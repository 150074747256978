import React from "react"
import { graphql, navigate, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import ComplexPage from "../components/pages/complex-page"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const PageComplex = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const contents = data?.contents
  const localSearchPages = data?.localSearchPages

  const translationPaths = {
   en: english?.path?.alias,
   es: spanish?.path?.alias,
 }

  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />

        <ComplexPage
          post={post}
          lang={post.langcode}
          bannerHeight="512px"
          buttonBanner="Els nostres valors"
          contents={contents}
          localSearchPages={localSearchPages}
          pageContext={pageContext}
          multipleButtons={<AnchorLinks ctas={post?.field_ctas} />}
         />

      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

const AnchorLinks = (props) => {
  const { ctas } = props;

  const isInternalUrl = (url) => {
    // You can define your logic here to check if the URL is internal or external
    // For example, you might check if the URL starts with your domain name
    // For the sake of simplicity, let's assume it starts with "/":
    return url.startsWith('/');
  };

  const isExternalUrl = (url) => {
    return !isInternalUrl(url);
  };

  const isAnchorLink = (url) => {
    // You can define your logic here to check if the URL is an anchor link
    // For example, you might check if the URL starts with "#" or contains a specific identifier
    // For the sake of simplicity, let's assume it starts with "#":
    return url.startsWith('#');
  };

  return (
    <div>
      {ctas &&
        ctas.length > 0 &&
        ctas.map((cta, index) => {
          if (isAnchorLink(cta.url)) {
            return (
              <AnchorLink
                key={index}
                to={cta.url}
                title={cta.title}
                className="btn btn-outline Normal-Bold-L---brown white p-r-0 m-r-24"
              />
            );
          } else if (isInternalUrl(cta.url)) {
            return (
              <Link
                key={index}
                to={cta.url}
                className="btn btn-outline Normal-Bold-L---brown white p-r-0 m-r-24"
              >
                {cta.title}
              </Link>
            );
          } else {
            return (
              <a
                key={index}
                href={cta.url}
                className="btn btn-outline Normal-Bold-L---brown white p-r-0 m-r-24"
                target="_blank"
                rel="noopener noreferrer"
              >
                {cta.title}
              </a>
            );
          }
        })}
    </div>
  );
};

export const pageQuery = graphql`
      query PageComplex($drupal_internal__nid: Int, $langcode: String!) {
        nodePage: nodePageTramuntana(
          drupal_internal__nid: {eq: $drupal_internal__nid},
          langcode:{eq: $langcode}
          ) {
          field_page_css_id
          langcode
          drupal_internal__nid
          id
          title
          drupal_id
          body {
            value
            summary
          }
          field_ctas {
            url: first
            title: second
          }
          created
          relationships {
            field_image {
              image_style_uri {
                _294x192
                _541x359
                _653_432
                large
                medium
                thumbnail
                wide
              }
            }
          }
        }
        contents: allNodeComplexContent(
          sort: {fields: field_weight, order: ASC}
          filter: {field_main_content: {drupal_internal__target_id: {eq: $drupal_internal__nid}}}
        ) {
          edges {
            node {
              field_anchor_id
              field_cta {
                title
                uri
                url
              }
              body {
                summary
                value
              }
              field_variation
              field_infografia_icon
              field_heading_color
              field_image_background_color
              field_image_position
              field_type_of_cta
              field_weight
              title
              field_content_width
              field_background_color
              relationships {
                webform {
                  drupal_internal__id
                  title
                  drupal_id
                  elements {
                    name
                    type
                    options {
                      label
                      value
                    }
                    attributes {
                      name
                      value
                    }
                  }
                }
                field_image {
                  image_style_uri {
                    wide
                    _653_432
                  }
                }
              }
            }
          }
        }
}
`

export default PageComplex
