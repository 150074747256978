import React, { useContext, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { LanguageContext } from '../../context'
import { homePagePaths, translate } from '../../translate'
import Modal from "../modal"
import ToastMessage from "../toast"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"

const ButtonModal = (props) => {

  const {
      children,
      theform,
      title,
      description,
      footerDescription,
      className,
      lang,
      backgroundimage,
      formData
    } = props



  const { t } = useContext(LanguageContext)

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  const [modalShow, setModalShow] = useState(false);
  const [messageShow, setMessageShow] = useState(false);

  const modalFooter = () => {
    return(
      <>
        <p>{footerDescription}</p>
      </>
    )
  }

  const sendMessage = (response) => {
    setModalShow(false)
    setMessageShow(true);
  }

  const { addToast } = useToasts()


  return (
   <>
     <div
     className="col-md-12 call-to-action">
       <div className="row">
         <div className="col-md-12 p-0">
           <button
           className={className}
           type="submit"
           onClick={() => setModalShow(true)}
           >{title}</button>
         </div>
       </div>
     </div>

     <ToastMessage
      show={messageShow}
      title={t("Dades enviades")}
      message={t("Dades enviades correctament. Aviat es posarà en contacte amb tu una persona de la Fundació Tramuntana. Gràcies!")}
      onClose={() => setMessageShow(false)}
      position="top-center"
      lang={props?.lang}
      />
     <Modal
       title={title}
       description={description}
       show={modalShow}
       onHide={() => setModalShow(false)}
       backgroundimage={backgroundimage}
       size='lg'
       className="modal-unida"
       theform={theform}
       footer={modalFooter()}
       sendMessage={(response) => sendMessage(response)}
       lang={props?.lang}
       id="modal-ask-prayer"
       children={children}
       formData={formData}
       />
   </>
 )
}

ButtonModal.propTypes = {
  title: PropTypes.string, // Optional: The title of the button
  className: PropTypes.string, // Optional: The CSS class for the button
  children: PropTypes.node, // Optional: The children of the component
  lang: PropTypes.string, // Optional: The language prop (used in ToastMessage and Modal components)
  backgroundimage: PropTypes.string, // Optional: The background image for the modal,
  theform: PropTypes.string, // The identifier for the form used in the modal
  id: PropTypes.string, // The identifier for the modal
  description: PropTypes.string, // The description text for the modal,
  footerDescription: PropTypes.string, // The description
};

ButtonModal.defaultProps = {
  title: "Demanar feina",
  className: "btn btn-outline btn-outline-visit-us my-2 my-sm-0",
  description: "Omple el següent formulari especificant el que necesites.",
  footerDescription: "Si necessites ajuda, posa’t en contacte amb nosaltres mitjançant el número de telèfon (+34) 937 88 16 61, de dilluns a diumenge de 9:00 a 17:00, o envia’ns un correu electrònic a info@fundaciotramuntana.es."
};


export default ButtonModal
